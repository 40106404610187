exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-buy-tickets-tsx": () => import("./../../../src/pages/buy-tickets.tsx" /* webpackChunkName: "component---src-pages-buy-tickets-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-travel-tips-tsx": () => import("./../../../src/pages/travel-tips.tsx" /* webpackChunkName: "component---src-pages-travel-tips-tsx" */),
  "component---src-posts-10-restaurants-in-cusco-you-must-go-index-tsx": () => import("./../../../src/posts/10-restaurants-in-cusco-you-must-go/index.tsx" /* webpackChunkName: "component---src-posts-10-restaurants-in-cusco-you-must-go-index-tsx" */),
  "component---src-posts-avoiding-altitude-sickness-cusco-travel-tips-index-tsx": () => import("./../../../src/posts/avoiding-altitude-sickness-cusco-travel-tips/index.tsx" /* webpackChunkName: "component---src-posts-avoiding-altitude-sickness-cusco-travel-tips-index-tsx" */),
  "component---src-posts-best-accommodations-in-cusco-luxury-hotels-index-tsx": () => import("./../../../src/posts/best-accommodations-in-cusco-luxury-hotels/index.tsx" /* webpackChunkName: "component---src-posts-best-accommodations-in-cusco-luxury-hotels-index-tsx" */),
  "component---src-posts-best-cusco-tours-you-must-see-tsx": () => import("./../../../src/posts/best-cusco-tours-you-must-see.tsx" /* webpackChunkName: "component---src-posts-best-cusco-tours-you-must-see-tsx" */),
  "component---src-posts-best-times-to-visit-cusco-and-machu-picchu-tsx": () => import("./../../../src/posts/best-times-to-visit-cusco-and-machu-picchu.tsx" /* webpackChunkName: "component---src-posts-best-times-to-visit-cusco-and-machu-picchu-tsx" */),
  "component---src-posts-choosing-the-perfect-circuit-for-your-machu-picchu-adventure-tsx": () => import("./../../../src/posts/choosing-the-perfect-circuit-for-your-machu-picchu-adventure.tsx" /* webpackChunkName: "component---src-posts-choosing-the-perfect-circuit-for-your-machu-picchu-adventure-tsx" */),
  "component---src-posts-cusco-to-machu-picchu-by-train-travel-guide-tsx": () => import("./../../../src/posts/cusco-to-machu-picchu-by-train-travel-guide.tsx" /* webpackChunkName: "component---src-posts-cusco-to-machu-picchu-by-train-travel-guide-tsx" */),
  "component---src-posts-how-to-buy-tickets-for-machu-picchu-2024-tsx": () => import("./../../../src/posts/how-to-buy-tickets-for-machu-picchu-2024.tsx" /* webpackChunkName: "component---src-posts-how-to-buy-tickets-for-machu-picchu-2024-tsx" */),
  "component---src-posts-machu-picchu-real-time-ticket-availability-tsx": () => import("./../../../src/posts/machu-picchu-real-time-ticket-availability.tsx" /* webpackChunkName: "component---src-posts-machu-picchu-real-time-ticket-availability-tsx" */)
}

